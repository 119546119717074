import svgTwo from '../../images/2.gif';
import svgThree from '../../images/123.gif';

export const homeObjOne = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Why bet with us?',

  description:
    'We offer a fun, low-fees, FAIR & SECURE decentralized betting experience you never had before. You can bet on Football, Basketball, Baseball, MMA, Boxing, Tennis, American Football, Rugby League, Rugby Union, Ice Hockey, Cricket, League of Legends and Counter-Strike.  ',
 
  imgStart: true,
  img: svgThree,
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  imgStart: true,
  img: svgTwo,
  alt: 'Piggybank',
  dark: true,
  primary: true,
  darkText: false,
  
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Why decentralized crypto betting is the best new way to bet and win:',
  headline: '',
  description:
    'In decentralized betting you only interact with smart contracts which are always permissionless and tamperproof, thus making sure that only you control your funds. Here you never make deposits or withdrawals. When you bet your money is locked into these open-source smart-contracts which have predetermined outcomes. Once the game finishes - the contract automatically allows you to redeem your winnings.',
};