import React from 'react';

import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarWrapper,
  SideBarMenu,
  SideBarLink,
  SideBtnWrap,
  SideBarRoute,
} from './SideBarElements';

const SideBar = ({ isOpen, toggle }) => {
  return (
    <>
     
    </>
  );
};

export default SideBar;
