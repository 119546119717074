import React, { useState } from 'react';

import video from '../../videos/video.mov';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';
import { Button } from '../ButtonElement';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <HeroContainer id='home'>
        <HeroBg>
          <VideoBg autoPlay loop muted src={video} type='video/mp4' />
        </HeroBg>



        <HeroContent>
       


          <HeroBtnWrapper>
            <Button
               as="a" href="https://app.sport-x-bet.xyz" target="blank" 
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary='true'
              dark='true'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-10}
            >
              <b>BET NOW</b>
            </Button>
            </HeroBtnWrapper>
            
          <HeroBtnWrapper>
            <Button
               as="a" href="https://www.mtpelerin.com/" 
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary='true'
              dark='true'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-10}
            >
              <b>Buy Crypto</b>
            </Button>
            </HeroBtnWrapper>

            <HeroBtnWrapper>
            <Button
               as="a" href="https://sport-x-bet.gitbook.io" target="blank" 
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary='true'
              dark='true'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-10}
            >
              <b>Documentation & Tutorials</b>
            </Button>
            </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
