import React from 'react';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements';




const Services = () => {
  return (
    <>
      <ServicesContainer id='services'>
        <ServicesH1>Decentralized, non-custodial & trustless. No middlemen!
        </ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
         
            <ServicesH2>Use your crypto directly from your web3 wallet with no need of extra steps like deposit or withdrawal.</ServicesH2>
            <ServicesP>
            
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
         
            <ServicesH2>You are always in charge. There is no registration and we do not need your personal information.</ServicesH2>
            <ServicesP>
            
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
           
            <ServicesH2>You can bet here, and you can win here. But there is no “house”, “bookmaker” or “sportsbook” which bets against you.  </ServicesH2>
            <ServicesP>
            
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
